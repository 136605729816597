@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local("Inter"), url("../public/font/Inter-Regular.woff2") format("woff2");
  /* Ajoutez d'autres formats de police au besoin (ttf, etc.) */
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600; /* Semibold */
  src: local("Inter"),
    url("../public/font/Inter-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700; /* Bold */
  src: local("Inter"), url("../public/font/Inter-Bold.woff2") format("woff2");
}

/* Personnalisation de la barre de défilement pour WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #9ca7b6;
  border-radius: 4px;
}

/* Personnalisation de la barre de défilement pour Firefox */
/* À ajouter si nécessaire */

.italic-placeholder::placeholder {
  font-style: italic;
}

.custom-audio {
  display: flex;
  border-radius: 10px;
  background-color: #01795d;
  color: white;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}
